import React, { Dispatch, SetStateAction } from 'react';
import { Event } from '../models/models';
import EventItem from './EventItem';
import Loading from './Loading';

interface Props {
  events: Event[],
  isLoading: boolean,
  setLimit: Dispatch<SetStateAction<number>>,
  limit: number,
}

const EventList: React.FC<Props> = ({ events, isLoading, setLimit, limit }) => {
  return (
    <>
      {isLoading ? <Loading /> :
        <>
          {
            events.map(event => (
              <EventItem event={event} key={event._id} />
            ))
          }
          <button
            onClick={() => setLimit(limit + 3)}
            className="mt-10 px-8 py-2 text-xs bg-gray-900 text-white hover:bg-gray-700 font-bold font-sans break-normal rounded"
          >
            Load More
          </button>
        </>
      }
    </>
  )
}

export default EventList;

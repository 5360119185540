import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import Home from './Home';
import AddressEvents from './AddressEvents';

function Router() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="/:address" element={<AddressEvents />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}

const Layout = () => (
  <div className="bg-gray-100 font-sans leading-normal tracking-normal">
    <div className="container w-full md:max-w-3xl mx-auto pt-20 pb-20">
      <div className="w-full px-4 md:px-6 text-xl text-gray-800 leading-normal">
        <div className='flex justify-between'>
          <div className="font-sans">
            <Link to="/">
              <h1 className="font-bold font-sans break-normal text-gray-900 pt-6 pb-2 text-3xl md:text-4xl">Whale Tracker</h1>
              <p className="text-sm md:text-base font-normal text-gray-600">All latest <span className="text-green-500">USDT</span> and <span className="text-blue-500">USDC</span> big moves (BETA)</p>
            </Link>
          </div>
        </div>
        <Outlet />
      </div>
    </div>
  </div>
);

const NoMatch = () => (
  <>
    <h2>Nothing to see here!</h2>
    <p>
      <Link to="/">Go to the home page</Link>
    </p>
  </>
);

export default Router;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Event } from './models/models';
import { formatAmount } from './utils/formats';
import EventList from './components/EventList';

const baseURL = 'https://whale-tracker-api.herokuapp.com';
// const baseURL = 'http://localhost:8000';
const defaultThreshold = 10000000;

const App: React.FC = () => {
  // const bottomRef = useRef<null | HTMLDivElement>(null);

  const [events, setEvents] = useState<Array<Event>>([]);
  const [isLoading, setLoading] = useState(true);
  const [limit, setLimit] = useState(5);
  const [disableReload, setDisableReload] = useState(false);
  const [range, setRange] = useState(defaultThreshold);

  const loadEvents = async () => axios({
    method: "GET",
    url: `${baseURL}/events`,
    headers: {
      "Content-Type": "application/json"
    },
    params: {
      limit,
      range
    }
  }).then(res => {
    setEvents(res.data);
    setLoading(false);
    setDisableReload(true);
    // bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    setTimeout(() => setDisableReload(false), 2000);
    return true;
  }).catch(e => {
    toast.error(e.message);
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    loadEvents();
  };

  useEffect(() => {
    setLimit(limit);
    setLoading(true);
    loadEvents();
  }, [limit]);

  return (
    <>
      <div className="relative py-3 lg mx-auto text-center">
        <div className="mt-4 bg-gray-900 shadow-md rounded text-left font-mono text-base">
          <div className="px-8 py-6 ">
            <form onSubmit={handleSubmit}>
              <label htmlFor="range" className="block mt-4 font-medium text-gray-900 dark:text-white">Fetch TRX greater than {formatAmount(String(range))}</label>
              <input
                id="range"
                type="range"
                min={defaultThreshold} max="1000000000" step="100000"
                value={range}
                onChange={e => setRange(Number(e.currentTarget.value))}
                className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
              />
              <div className="flex justify-between items-baseline">
                <button
                  type="submit"
                  disabled={disableReload}
                  className={`${disableReload ? 'bg-green-400' : 'bg-green-500'} mt-4 text-white py-2 px-6 rounded hover:bg-green-600`}
                >
                  Fetch
                </button>

                <button
                  disabled={disableReload}
                  type="button"
                  onClick={() => loadEvents()}
                  className={`${disableReload ? 'bg-green-400' : 'bg-green-500'} mt-4 text-white py-2 px-6 rounded hover:bg-green-600`}
                >
                  Fetch Latest TRX
                </button>
              </div>

            </form>
          </div>
        </div>
      </div>

      <EventList
        events={events}
        isLoading={isLoading}
        setLimit={setLimit}
        limit={limit}
      />
    </>
  );
}

export default App;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { Event } from './models/models';
import EventList from './components/EventList';

const baseURL = 'https://whale-tracker-api.herokuapp.com';
// const baseURL = 'http://localhost:8000';

const AddressEvents: React.FC = () => {
  const { address } = useParams();

  const [events, setEvents] = useState<Array<Event>>([]);
  const [isLoading, setLoading] = useState(true);
  const [limit, setLimit] = useState(5);

  const loadEvents = async () => axios({
    method: "GET",
    url: `${baseURL}/events`,
    headers: {
      "Content-Type": "application/json"
    },
    params: {
      limit,
      address
    }
  }).then(res => {
    setEvents(res.data);
    setLoading(false);
    return true;
  }).catch(e => {
    toast.error(e.message);
  });

  useEffect(() => {
    setLimit(limit);
    setLoading(true);
    loadEvents();
  }, [limit, address]);

  return (
    <>
      <div className="relative py-3 lg mx-auto text-center">
        <div className="mt-4 bg-gray-900 shadow-md rounded text-left font-mono text-base">
          <div className="px-8 py-6 ">
            <p className="block mt-2 font-medium text-gray-900 dark:text-white">Events of: {address}</p>
          </div>
        </div>
      </div>

      <EventList
        events={events}
        isLoading={isLoading}
        setLimit={setLimit}
        limit={limit}
      />
    </>
  );
}

export default AddressEvents;

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from 'react-toastify';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        closeOnClick={true}
        pauseOnHover={true}
        theme='dark'
      />

      <App />
    </BrowserRouter>
  </React.StrictMode>
);
